<template>
  <v-container
    fluid
    tag="section"
  >
    <h1>Für Fragen zum System...</h1>
    <p>
      ... und bei Problemen wenden Sie sich bitte an <a href="mailto:ergebnisdienst@stb.de">ergebnisdienst@stb.de</a>
    </p>

    <h1>Rechtliche Hinweise</h1>
    <h3>Impressum</h3>
    <p>
      <b>Seitenbetreiber i.S.d. § 5 TMG:</b><br>
      Schwäbischer Turnerbund (STB) e.V.<br>
      Fritz-Walter-Weg 19<br>
      70372 Stuttgart<br>
      Telefon: +49 (0) 711 28077200<br>
      E-Mail: <a href="mailto:info@stb.de">info@stb.de</a><br>
      Internet: <a href="http://www.stb.de" target="_blank">www.stb.de</a>
    </p>

    <h3>Vereinsregister:</h3>
    <p>
      Registergericht: Amtsgericht Stuttgart<br>
      Registernummer: VR 628
    </p>

    <h3>Vertretungsberechtigter Vorstand:</h3>
    <p>Markus Frank</p>

    <h3>Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:</h3>
    <p>DE 147218427</p>

    <h3>Inhaltlich Verantwortlicher gemäß § 55 Abs. 2 RStV:</h3>
    <p>
      Matthias Ranke<br>
      (Anschrift wie oben)
    </p>

    <h3>Informationen zur Online-Streitbeilegung:</h3>
    <p>
      Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit.<br>
      Diese Plattform finden Sie unter folgendem Link: <a href="http://ec.europa.eu/consumers/odr/" target="_blank">ec.europa.eu/consumers/odr/</a>.<br>
      Verbraucher können diese Plattform nutzen, um ihre Streitigkeiten aus Online-Verträgen beizulegen.<br>
      <b>Hinweis gemäß § 36 VSBG:</b><br>
      Wir werden nicht an alternativen Streitschlichtungsverfahren im Sinne des § 36 VSBG teilnehmen. Die Nutzung einer alternativen Schlichtungsstelle stellt keine zwingende Voraussetzung für das Anrufen zuständiger ordentlicher Gerichte dar.
    </p>

    <h3>Urheberrecht und Bildnachweise:</h3>
    <p>
      Die Inhalte von www.stb.de sind - soweit nicht abweichend angegeben -  urheberrechtlich geschützt.<br>
      Verwendete Fotografien sind ggf. mit Bildnachweisen gekennzeichnet oder unten aufgeführt, soweit sie nicht selbst angefertigt wurden.<br>
      Die Verwendung von Fotografien auf Drittseiten ist nur im Rahmen der jeweiligen Lizenz der Urheber möglich.<br>Falls Bildnachweise vorhanden sind, bitte hier angeben (sofern Nennung im Impressum zulässig ist)
    </p>

    <h3>Erstellt durch:</h3>
    <p>
      © IT-Recht-Kanzlei DURY – www.dury.de<br>
      © Website-Check GmbH – www.website-check.de
    </p>
  </v-container>
</template>

<script>

export default {
  name: 'Impress'
}
</script>
